<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getKuponi)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/kuponi/create')" v-if="$utils('roleUser', 'Kuponi', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table
					v-if="kuponi.length"
					:data="kuponi"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getKuponi)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="left"
						prop="naziv"
						:label="$t('kuponi.naziv')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="left"
						prop="vrsta"
						:label="$t('kuponi.vrsta')"
						sortable="custom">
					<template slot-scope="scope">
						<span>{{scope.row.vrsta}}</span>
						<span v-if="scope.row.kupon"> / {{scope.row.kupon}}</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="iskorisceno"
						:label="$t('kuponi.iskorisceno')"
						sortable="custom">
					<template slot-scope="scope">
						<span>{{scope.row.iskorisceno}}/{{scope.row.kolicina}}</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						:label="$t('kuponi.datumOdDo')">
					<template slot-scope="scope">
						<p>{{$utils('formatDate', scope.row.datum_vreme_od, 'DD.MM.YYYY HH:mm')}}</p>
						<p>{{$utils('formatDate', scope.row.datum_vreme_do, 'DD.MM.YYYY HH:mm')}}</p>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="min_cena"
						:label="$t('kuponi.minCena')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.min_cena || null)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="fiksni_popust"
						:label="$t('kuponi.fiksniPopust')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.fiksni_popust || null)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="procenat_popust"
						:label="$t('kuponi.popust')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.procenat_popust || null)}} %
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="fiksna_cena"
						:label="$t('kuponi.fiksnaCena')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.fiksna_cena || null)}}
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="id_vezane_linije"
						:label="$t('kuponi.linije')"
						sortable="custom">
					<template slot-scope="scope">
						{{scope.row.id_vezane_linije.join(', ')}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="pravac"
						:label="$t('kuponi.pravac')"
						sortable="custom">
				</el-table-column>
				<el-table-column

						align="center"
						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.pregled')" placement="top" v-if="$utils('roleUser', 'Kuponi', 'edit')">
							<el-button type="warning" icon="fas fa-eye" @click="$utils('goTo', '/kuponi/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Kuponi', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('kuponi', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getKuponi" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'kuponi-list',
	data() {
		return {
			kuponi: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('kuponi.kuponi')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getKuponi()]);
	},
	methods: {
		getKuponi() {
			this.gridLoad = true;
			return this.$get('kuponi', this.$utils('getParams')).then(data => {
				this.kuponi = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
